import { Carousel } from 'antd'
import styled from 'styled-components'

export const CalendarContainer = styled.div`
  width: 99%;
  height: 100%;
  color: white;
  padding: 16px;
  border-radius: 8px;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75%;
  width: 89%;
`

export const FixedHeaderSection = styled.div`
  position: sticky;
  top: 0;
  z-index: 10;
  margin-bottom: 1px;
  display: flex;
`

export const HeaderRow = styled.div`
  display: grid;
  margin-top: -0.8px;
  grid-template-columns: repeat(7, 1fr);
  border-bottom: none;
  min-width: 620px;
  flex: 1;
  background: #050624;
`

export const HeaderCell = styled.div`
  padding-bottom: 8px;
  margin-top: -15px;
  text-align: center;
  font-weight: 500;
  border-bottom: 1px solid #343755;
  background: #050624;
`

export const EmptyHeaderCell = styled.div`
  width: 80px;
  position: relative;
  z-index: 0;
`

export const ScrollableGridContainer = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;
`
export const MainContentContainer = styled.div`
  display: flex;
  flex: 1;
  overflow: visible;
  margin-bottom: 30px;
`

export const TimeColumnContainer = styled.div`
  width: 80px;
  flex-shrink: 1;
  postion: fixed;
  top: 0;
  left: 0;
`

export const CarouselContainer = styled.div`
  flex: 1;
  overflow: hidden;
`

export const CalendarGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  min-width: 620px;
`

export const TimeCell = styled.div`
  height: 67px;
  font-weight: 500;
  position: relative;
  z-index: 1;
`
export const Time = styled.div`
  position: relative;
  top: -11px;
  left: 20px;
  z-index: 2;
`
export const CalendarCell = styled.div`
  height: 67px;
  padding-top: 2.5px;
  padding-right: 4px;
  padding-left: 4px;
  padding-bottom: 4.5px;
  border-bottom: 1px solid #343755;
  display: flex;
  flex-direction: column;
  gap: 2px;
  overflow: hidden;
  width: 100%;

  &:nth-last-child(-n + 7) {
    border-bottom: none;
  }
`

// New styled component for quarter rows within each calendar cell.
export const QuarterRow = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2px;
`

export const StyledCarousel = styled(Carousel)`
  .slick-slide {
    overflow: hidden;
  }

  .slick-track {
    display: flex;
    align-items: flex-start;
  }
`
