import { configureStore } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux'
import type { TypedUseSelectorHook } from 'react-redux'
import { persistStore, persistReducer } from 'redux-persist'
import currentReducer from './current'
import doctorReducer from './doctor'
import lobbyReducer from './lobby'
import meReducer from './me'
import notesReducer from './notes'
import scanReducer from './scan'
import scheduleReducer from './schedule'
import studioReducer from './studio'

const persistConfig = {
  key: 'root',
  storage: localStorage,
  whitelist: ['studio', 'doctor'],
}
export const store = configureStore({
  reducer: {
    studio: studioReducer,
    doctor: doctorReducer,
    lobby: lobbyReducer,
    current: currentReducer,
    notes: notesReducer,
    me: meReducer,
    scan: scanReducer,
    schedule: scheduleReducer,
  },
})

//export const persistor = persistStore(store)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
