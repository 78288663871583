import React, { useEffect, useState } from 'react'
import { Layout, Spin } from 'antd'
import { AppConstant } from 'AppConstant'
import AuthenticatedApp from 'AuthenticatedApp'
import { useAuth } from './hooks/useAuth'
import Logout from './images/logout.svg'
import { setDoctor } from './redux/doctor'
import { loadLobbyQueueAsync } from './redux/lobby'
import { setOTPSent, setPhoneNumber } from './redux/me'
import { useAppDispatch, useAppSelector } from './redux/store'
import { setStudio } from './redux/studio'
import Login from './tabs/login'
import Setup from './tabs/setup'
import Verify from './tabs/verify'

const App = () => {
  const { isAuthLoading, isAuthenticated, currentRole, user, checkAuthStatus, signOut } = useAuth()

  const dispatch = useAppDispatch()

  const studioCode = useAppSelector((state) => state.studio.studioCode)
  const clinicId = useAppSelector((state) => state.studio.clinic?.id)
  const userId = useAppSelector((state) => state.doctor.userId)
  const isOtpSent = useAppSelector((state) => state.me.isOTPSent)

  useEffect(() => {
    if (!isAuthLoading && isAuthenticated && currentRole === AppConstant.CHIROPRACTOR) {
      dispatch(
        setDoctor({
          userId: user.userId,
          firstname: user.firstname,
          lastname: user.lastname,
        }),
      )
    }
  }, [isAuthLoading, isAuthenticated])

  useEffect(() => {
    const localStudioStr = localStorage.getItem(AppConstant.LOCAL_STUDIO)

    console.log(localStudioStr)
    if (localStudioStr) {
      try {
        const localStudio = JSON.parse(localStudioStr)
        if (localStudio.name && localStudio.code) {
          dispatch(setStudio(localStudio))
          dispatch(loadLobbyQueueAsync({ clinicId: localStudio.id }))
        }
      } catch (err) {
        console.log(err)
      }
    }
  }, [])

  // useEffect(() => {
  //   function onConnect() {
  //     console.log('connected')
  //   }

  //   function onDisconnect() {
  //     console.log('dis connected')
  //   }

  //   // function onFooEvent(value) {
  //   //   setFooEvents((previous) => [...previous, value])
  //   // }

  //   setInterval(() => {
  //     socket.emit('events', 'workld', (val: any) => {
  //       console.log(val)
  //     })
  //   }, 3000)

  //   socket.on('connect', onConnect)
  //   socket.on('disconnect', onDisconnect)
  //   //socket.on('foo', onFooEvent)

  //   return () => {
  //     socket.off('connect', onConnect)
  //     socket.off('disconnect', onDisconnect)
  //   }
  // }, [])

  console.log('test')

  if (isAuthLoading) {
    return (
      <div
        style={{
          height: '100vh',
          width: '100vw',
          background: '#050624',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Spin size="large" />
      </div>
    )
  } else if (!isAuthLoading && !isAuthenticated) {
    return (
      <Login
        onAuthComplete={() => {
          checkAuthStatus()
        }}
      ></Login>
    )
  }
  // else if (!isAuthLoading && !isAuthenticated && isOtpSent) {
  //   return (
  //     <Verify
  //       onAuthComplete={() => {
  //         checkAuthStatus()
  //       }}
  //     ></Verify>
  //   )
  // }
  else if (!isAuthLoading && isAuthenticated && currentRole === AppConstant.CHIROPRACTOR) {
    if (studioCode) {
      return <AuthenticatedApp></AuthenticatedApp>
    } else {
      return (
        <Setup
          onStudioSetupComplete={(data: any) => {
            dispatch(setStudio(data))
            dispatch(loadLobbyQueueAsync({ clinicId: data?.id }))
          }}
        ></Setup>
      )
    }
  }

  return (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        background: '#050624',
        color: '#fff',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
      }}
    >
      Oops! Looks like no doctor found
      <div
        onClick={() => {
          signOut().finally(() => {})
        }}
        style={{
          background: 'transparent',
          color: '#007F7C',
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          marginTop: 8,
        }}
      >
        <img src={Logout} style={{ marginRight: '8px' }}></img>
        Logout
      </div>
    </div>
  )
}

export default App
