import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Spin } from 'antd'
import type { CarouselRef } from 'antd/es/carousel'
import { format, addDays, startOfWeek, getHours } from 'date-fns'
import {
  CalendarCell,
  CalendarContainer,
  CalendarGrid,
  CarouselContainer,
  EmptyHeaderCell,
  FixedHeaderSection,
  HeaderCell,
  HeaderRow,
  LoadingContainer,
  MainContentContainer,
  ScrollableGridContainer,
  StyledCarousel,
  Time,
  TimeCell,
  TimeColumnContainer,
  QuarterRow,
} from './Calendar.styled'
import { getScheduleByWeek } from '../../redux/schedule'
import { useAppDispatch, useAppSelector } from '../../redux/store'

const Calendar = () => {
  const dispatch = useAppDispatch()
  const clinicId = useAppSelector((state) => state.studio.clinic?.id)
  const TOTAL_WEEKS = 2
  const [centralWeekIndex, setCentralWeekIndex] = useState(0)
  const [weekStarts, setWeekStarts] = useState<Date[]>([])
  const [loading, setLoading] = useState(true)
  const [calendarData, setCalendarData]: any = useState({})
  const carouselRef = useRef<CarouselRef>(null)

  const timeSlots = [
    { label: '8 AM', value: 8 },
    { label: '9 AM', value: 9 },
    { label: '10 AM', value: 10 },
    { label: '11 AM', value: 11 },
    { label: '12 PM', value: 12 },
    { label: '1 PM', value: 13 },
    { label: '2 PM', value: 14 },
    { label: '3 PM', value: 15 },
    { label: '4 PM', value: 16 },
    { label: '5 PM', value: 17 },
    { label: '6 PM', value: 18 },
    { label: '7 PM', value: 19 },
    { label: '8 PM', value: 20 },
  ]

  const getCurrentWeekMonday = useCallback(() => {
    const today = new Date()
    return startOfWeek(today, { weekStartsOn: 1 }) // ISO week starts on Monday
  }, [])

  const generateDays = useCallback((weekStart: Date) => {
    const days = []

    for (let i = 0; i < 7; i++) {
      const day = addDays(weekStart, i)
      days.push({
        date: day,
        dayName: format(day, 'EEE').toUpperCase(),
        dayNumber: format(day, 'd'),
      })
    }

    return days
  }, [])

  const headerDays = weekStarts.length > 0 ? generateDays(weekStarts[centralWeekIndex]) : []

  const handleCarouselChange = useCallback(
    (currentSlide: number) => {
      if (currentSlide !== centralWeekIndex) {
        setLoading(true)
        setCalendarData({})
        setCentralWeekIndex(currentSlide)
      }
    },
    [centralWeekIndex],
  )

  const assignColor = useCallback((isMember: any, needsFullSlot: any, c: any) => {
    const count = parseInt(c)

    if (count === 1) {
      return !isMember && needsFullSlot ? '#e5e7eb' : '#007f7c'
    }

    return '#007f7c'
  }, [])

  const assignOpacity = useCallback((c: any) => {
    const count = parseInt(c)

    if (count === 1) {
      return 1
    } else if (count === 2) {
      return 0.75
    } else if (count >= 3) {
      return 0.5
    }

    return ''
  }, [])

  const convertUtcToEst = useCallback((utcString: string): string => {
    const dateUTC = new Date(utcString)

    // Format to Eastern Time (New York)
    const options: any = {
      timeZone: 'America/New_York',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    }
    return dateUTC.toLocaleString('en-US', options)
  }, [])

  const transformDataForCalendar = useCallback((data: any[]) => {
    return data.reduce(
      (acc, item) => {
        const date = new Date(convertUtcToEst(item.appointmentTime))
        const dayLabel = format(date, 'EEE').toUpperCase() // Make dayLabel uppercase
        const hour = getHours(date)
        const minutes = date.getMinutes()
        const quarter = Math.floor(minutes / 15) // 0 => 00, 1 => 15, 2 => 30, 3 => 45

        if (hour >= 8 && hour < 20) {
          if (!acc[dayLabel]) {
            acc[dayLabel] = {}
          }
          if (!acc[dayLabel][hour]) {
            // Initialize an array for the four quarter slots
            acc[dayLabel][hour] = [[], [], [], []]
          }

          acc[dayLabel][hour][quarter].push({
            id: item.appointmentTime,
            color: assignColor(item.isMember, item.needsFullSlot, item.count),
            opacity: assignOpacity(item.count),
            outlined: item.isMember && item.needsFullSlot ? true : false,
            count: item.count,
            isMember: item.isMember,
            date: format(date, 'yyyy-MM-dd'),
          })
        }

        return acc
      },
      {} as Record<string, Record<number, any[][]>>,
    )
  }, [])

  useEffect(() => {
    const currentWeekStart = getCurrentWeekMonday()
    const weeks = []

    for (let i = 0; i < TOTAL_WEEKS; i++) {
      weeks.push(addDays(currentWeekStart, i * 7))
    }

    setWeekStarts(weeks)
  }, [])

  useEffect(() => {
    const fetchSchedule = async () => {
      if (weekStarts.length > 0) {
        try {
          const formattedStartDate = format(weekStarts[centralWeekIndex], 'yyyy-MM-dd HH:mm:ss')
          const formattedEndDate = format(addDays(weekStarts[centralWeekIndex], 6), 'yyyy-MM-dd HH:mm:ss')
          const res = await dispatch(
            getScheduleByWeek({
              startDate: formattedStartDate,
              endDate: formattedEndDate,
              clinicId: clinicId,
            }),
          ).unwrap()

          if (res?.data) {
            const uniqueData = await res?.data.filter(
              (item: any, index: any, self: any) =>
                index === self.findIndex((t: any) => t.appointmentTime === item.appointmentTime),
            )

            const calendarData = transformDataForCalendar(uniqueData)
            const formattedDate = format(weekStarts[centralWeekIndex], 'yyyy-MM-dd')
            setCalendarData((prevData: any) => ({
              ...prevData,
              [formattedDate]: calendarData,
            }))
          }
          setLoading(false)
        } catch (err) {
          console.error('Error fetching schedule:', err)
          setLoading(false)
        }
      }
    }

    fetchSchedule()
  }, [weekStarts, centralWeekIndex])

  return (
    <CalendarContainer>
      <>
        <FixedHeaderSection>
          <EmptyHeaderCell></EmptyHeaderCell>
          <HeaderRow>
            {headerDays?.map((day, index) => (
              <HeaderCell key={index}>
                <div style={{ marginBottom: '4px' }}>{day.dayNumber}</div>
                <div>{day.dayName}</div>
              </HeaderCell>
            ))}
          </HeaderRow>
        </FixedHeaderSection>
        <MainContentContainer>
          <TimeColumnContainer>
            {timeSlots?.map((timeSlot, index) => (
              <TimeCell key={index}>
                <Time>{timeSlot.label}</Time>
              </TimeCell>
            ))}
          </TimeColumnContainer>
          {loading ? (
            <LoadingContainer>
              <Spin size="large" />
            </LoadingContainer>
          ) : (
            <CarouselContainer>
              <StyledCarousel
                ref={carouselRef}
                dots={false}
                infinite={false}
                speed={500}
                beforeChange={(_, next) => handleCarouselChange(next)}
                initialSlide={centralWeekIndex}
                swipeToSlide
                draggable
                swipe
              >
                {weekStarts?.map((weekStart, index) => (
                  <div key={index}>
                    <ScrollableGridContainer>
                      <CalendarWeek
                        weekStart={weekStart}
                        calendarData={calendarData[format(weekStart, 'yyyy-MM-dd')] || {}}
                        timeSlots={timeSlots}
                      />
                    </ScrollableGridContainer>
                  </div>
                ))}
              </StyledCarousel>
            </CarouselContainer>
          )}
        </MainContentContainer>
      </>
    </CalendarContainer>
  )
}

export default Calendar

interface EventBarProps {
  count: number
  color: string
  outlined?: boolean
  opacity: number
}

const EventBar: React.FC<EventBarProps> = ({ count, color, outlined, opacity }) => {
  let barWidth = '100%'

  if (count === 1) {
    barWidth = '100%'
  } else if (count === 2) {
    barWidth = '50%'
  } else if (count === 3) {
    barWidth = '33.33%'
  }

  return (
    <div style={{ display: 'flex', gap: '2px', width: '100%', marginTop: '0.5px' }}>
      {Array.from({ length: count }).map((_, index) => (
        <div
          key={index}
          style={{
            width: barWidth,
            height: '11.5px',
            background: color,
            outline: outlined ? '1px dashed' : 'none',
            outlineOffset: '-1px',
            opacity: opacity,
            borderRadius: '2px',
            gap: 2,
          }}
        />
      ))}
    </div>
  )
}

interface CalendarWeekProps {
  weekStart: Date
  calendarData: any
  timeSlots: { label: string; value: number }[]
}

const CalendarWeek: React.FC<CalendarWeekProps> = ({ weekStart, calendarData, timeSlots }) => {
  const generateDays = useCallback(() => {
    const days = []
    for (let i = 0; i < 7; i++) {
      const day = addDays(weekStart, i)
      days.push({
        date: day,
        dayName: format(day, 'EEE').toUpperCase(),
        dayNumber: format(day, 'd'),
      })
    }
    return days
  }, [weekStart])

  const [days, setDays] = useState(generateDays())

  const getEventsForQuarter = useCallback(
    (dayLabel: any, time: any, quarter: number) => {
      return (calendarData?.[dayLabel]?.[time] && calendarData[dayLabel][time][quarter]) || []
    },
    [calendarData],
  )

  return (
    <CalendarGrid>
      {timeSlots?.map((timeSlot, index) => (
        <React.Fragment key={index}>
          {days.map((day, dayIndex) => (
            <CalendarCell key={dayIndex}>
              {[0, 1, 2, 3].map((quarter) => {
                const events = getEventsForQuarter(day.dayName, timeSlot.value, quarter)
                return (
                  <QuarterRow key={quarter}>
                    {events.map((event: any, idx: any) => (
                      <EventBar
                        key={idx}
                        count={event.count}
                        color={event.color}
                        opacity={event.opacity}
                        outlined={event.outlined}
                      />
                    ))}
                  </QuarterRow>
                )
              })}
            </CalendarCell>
          ))}
        </React.Fragment>
      ))}
    </CalendarGrid>
  )
}
