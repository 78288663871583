import { ThemeConfig } from 'antd'

const theme: ThemeConfig = {
  token: {
    colorPrimary: '#007F7C',
    // colorFillTertiary: '#007F7C',
    // colorFillQuaternary: '#007F7C',
    // colorFillSecondary: '#007F7C',
    borderRadius: 4,
    colorText: '#E5E7EB',
    fontFamily: 'WixMadeforDisplay',
    fontSize: 14,
    fontSizeXL: 20,
  },
  components: {
    // Button: {
    //   colorPrimary: '#007F7C',
    //   borderRadius: 4,
    // },
    Input: {
      controlHeight: 48,
      activeShadow: 'none',
      colorTextDisabled: '#4f505e',
      borderRadius: 4,
      colorTextPlaceholder: '#2D2F4A;',
      colorText: '#D2D3E0',
      colorPrimary: '#e5e7eb',
      activeBg: 'transparent',
      colorErrorBorderHover: '#F00',
      colorError: '#F00',
      colorErrorActive: '#F00',
      colorErrorOutline: 'none',
      hoverBorderColor: '#2c2d3c',
      colorBgContainer: 'transparent',
      colorBorder: '#2c2d3c',
      inputFontSize: 13,
    },
    Drawer: {
      colorBgElevated: '#10123D',
      colorIcon: '#D2D3E0',
      colorText: '#E5E7EB',
      fontWeightStrong: 700,
      fontSize: 13,
    },
    Button: {
      colorPrimary: '#007F7C',
      controlHeight: 48,
      colorBgContainer: '#10123D',
      colorPrimaryActive: '#004341',
      colorPrimaryBgHover: '#007F7C',
      colorPrimaryBorder: 'red',
      fontWeight: 700,
      borderRadius: 4,
      defaultBorderColor: 'none',
      defaultBg: '#050624',
      defaultHoverBg: '#050624',
      defaultActiveBorderColor: 'none',
      defaultHoverBorderColor: 'none',
      defaultHoverColor: '#D2D3E0',
      defaultColor: '#D2D3E0',
      defaultActiveColor: '#D2D3E0',
      defaultActiveBg: '#171927',
      colorBgContainerDisabled: '#0e3130',
      borderColorDisabled: 'none',
      fontSize: 16,
    },
    Layout: {
      bodyBg: '#050624',
      headerBg: '#050624',
      footerBg: '#050624',
      headerHeight: 88,
      siderBg: '#191A23',
    },
    Menu: {
      itemBg: '#191A23',
      itemHeight: 27,
      itemSelectedBg: '#007F7C',
      itemSelectedColor: '#FFFEFF',
      itemHoverColor: '#9a9b9e',
      iconSize: 16,
      itemPaddingInline: 6,
      itemMarginInline: 13,
      //itemMarginBlock: 13,
    },
    Modal: {
      // colorBgMask: 'none',
      titleFontSize: 13,
      titleColor: '#E5E7EB',
      contentBg: 'transparent',
      headerBg: '#10123D',
      paddingMD: 0,
      paddingContentHorizontalLG: 0,
    },
    Segmented: {
      trackBg: '#10123D',
      itemActiveBg: '#2A2D56',
      itemColor: '#FFF',
      itemSelectedBg: '#2A2D56',
      itemSelectedColor: '#FFF',
      borderRadiusLG: 4,
      colorBorder: 'red',
      trackPadding: 0,
      fontSizeLG: 13,
    },
    Card: {
      colorBgContainer: '#10123D',
      controlHeight: 66,
      borderRadius: 4,
      colorBorder: '#2A2D50',
      colorPrimaryBorder: '#2A2D50',
      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    },
    Progress: {
      circleTextColor: '#e5e7eb',
      colorSuccess: '#e5e7eb',
    },
  },
}
export default theme
