import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { API_URL, AppConstant } from 'AppConstant'
import { APIStatus } from '../../redux/types'

interface Appointment {
  appointmentTime: string
  needsFullSlot: boolean
  count: string
  isMember: boolean
}
interface ScheduleState {
  schedule: Array<Appointment>
  scheduleStatus: APIStatus
}
const initialState: ScheduleState = {
  schedule: [],
  scheduleStatus: APIStatus.idle,
}

export const getScheduleByWeek = createAsyncThunk(
  'schedule/getScheduleByWeek',
  async ({ startDate, endDate, clinicId }: { startDate: any; endDate: any; clinicId: any }, thunkAPI) => {
    const token = localStorage.getItem(AppConstant.ACCESS_TOKEN)

    let response = await axios
      .get(`${API_URL}/clinic/schedule?startDate=${startDate}&endDate=${endDate}&clinicId=${clinicId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'X-Platform-Id': process.env.REACT_APP_PLATFORM_ID,
        },
      })
      .then((res) => res)
      .catch((err) => err.response)

    if (response.data && response.data.code === 'success') {
      return thunkAPI.fulfillWithValue(response.data)
    } else {
      return thunkAPI.rejectWithValue(response.data.message)
    }
  },
)
const scheduleSlice = createSlice({
  name: 'schedule',
  initialState,
  reducers: {
    resetSchedule: (state) => {
      state.schedule = []
    },
  },
  extraReducers(builder) {
    //getScheduleByWeek
    builder.addCase(getScheduleByWeek.fulfilled, (state, action) => {
      state.schedule = action.payload.data
      state.scheduleStatus = APIStatus.fulfilled
    })
    builder.addCase(getScheduleByWeek.pending, (state, action) => {
      state.scheduleStatus = APIStatus.pending
    })
    builder.addCase(getScheduleByWeek.rejected, (state, action) => {
      state.scheduleStatus = APIStatus.rejected
    })
  },
})

export const { resetSchedule } = scheduleSlice.actions

export default scheduleSlice.reducer
