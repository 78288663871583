import { useEffect, useRef, useState } from 'react'
import { Button, Input } from 'antd'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { API_URL, AppConstant } from 'AppConstant'
import { useAuth } from 'hooks/useAuth'
import KLogo from '../../images/initialLogo.svg'
import Logout from '../../images/logout.svg'
import { useAppDispatch } from '../../redux/store'

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #050624;
`

const LoginBox = styled.div`
  width: 384px;
  height: auto;
  margin-bottom: 150px;
`
const LoginDetails = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`
const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`
const Logo = styled.img`
  margin-bottom: 66px;
`

const Title = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  color: #007f7c;
  font-size: 20px;
  font-style: normal;
  display: flex;
  align-item: center;
  margin-bottom: 16px;
  font-weight: 800;
  line-height: 130%;
`

const UserLogoWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-item: center;
`
const InputWrapper = styled.div`
  width: 100%;
`

const Label = styled.label`
  color: #e5e7eb;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  display: inline-block;
  margin-bottom: 10px;
  margin-left: 2px;
`

const ErrorWrapper = styled.div`
  width: 100%;
  color: #e33939;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  display: inline-block;
  margin-top: 5px;
  margin-left: 2px;
`

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 29px;
`
export enum ErrorStatus {
  error = 'error',
  warning = 'warning',
  emptyString = '',
}

export default function AuthScan() {
  const navigate = useNavigate()
  const [code, setCode] = useState('')
  const [error, setError] = useState('')
  const [buttonLoading, setButtonLoading] = useState(false)
  const [errorStatus, setErrorStatus] = useState<ErrorStatus>(ErrorStatus.emptyString)

  async function getStudio() {
    setButtonLoading(true)
    if (code === '') {
      setError('empty')
      setButtonLoading(false)
      setErrorStatus(ErrorStatus.error)
    } else {
      let response = await axios
        .post(
          API_URL + '/auth/scan-request/login/verify',
          { clinicCode: code },
          {
            headers: {
              'X-Platform-Id': process.env.REACT_APP_PLATFORM_ID,
            },
          },
        )
        .then((res) => res)
        .catch((err) => err.response)

      if (response && response.data.code === 'success' && response.data.data) {
        localStorage.setItem(AppConstant.ACCESS_TOKEN_SCAN_AUTH, response.data.data.accessToken)
        let tokenParts = response.data.data.accessToken.split('.')
        let tokenPayload = tokenParts[1]
        let tokenPayloadStr = atob(tokenPayload)
        let decodedToken: any = JSON.parse(tokenPayloadStr)
        localStorage.setItem(AppConstant.CLINIC_ID, decodedToken?.sub)
        navigate('/scan/home')
      } else if (response && response.data.code === 'clinic_not_found') {
        setError('no_clinic')
        setButtonLoading(false)
      } else {
        setButtonLoading(false)
      }
    }
  }

  return (
    <Container>
      <LoginBox>
        <LogoWrapper>
          <Logo src={KLogo}></Logo>
        </LogoWrapper>
        <LoginDetails
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              getStudio()
            }
          }}
        >
          <InputWrapper>
            <Label>Studio Code</Label>
            <Input
              value={code}
              inputMode="numeric"
              onChange={(e) => {
                setCode(e.target.value)
                setError('')
                setErrorStatus(ErrorStatus.emptyString)
              }}
              status={errorStatus}
              placeholder="Enter code"
            ></Input>
            {error === 'empty' && <ErrorWrapper>Please enter studio code !</ErrorWrapper>}
            {error === 'no_clinic' && <ErrorWrapper>Studio not found !</ErrorWrapper>}
            <ButtonWrapper>
              <Button type="primary" loading={buttonLoading} style={{ width: '100%' }} onClick={getStudio}>
                {buttonLoading ? '' : 'Set Up'}
              </Button>
            </ButtonWrapper>
          </InputWrapper>
        </LoginDetails>
      </LoginBox>
    </Container>
  )
}
