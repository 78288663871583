import { createSlice } from '@reduxjs/toolkit'

interface StudioState {
  studioCode: string
  studioName: string
  clinic: any
}

const initialState: StudioState = {
  studioCode: '',
  studioName: '',
  clinic: null,
}

const studioSlice = createSlice({
  name: 'studio',
  initialState,
  reducers: {
    setStudio: (state, action) => {
      state.studioName = action.payload.name
      state.studioCode = action.payload.code
      state.clinic = action.payload
    },
  },
})

export const { setStudio } = studioSlice.actions
export default studioSlice.reducer
