import { useCallback, useEffect, useState } from 'react'
//import * as jwt from 'jsonwebtoken'
import { AppConstant } from 'AppConstant'

export function useAuth() {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [isAuthLoading, setIsAuthLoading] = useState(true)
  const [token, setToken] = useState<string>('')
  const [user, setUser] = useState<any>(null)
  let [currentRole, _setCurrentRole] = useState<any>([])

  function setCurrentRole(role: string) {
    localStorage.setItem(AppConstant.CURRENTROLE_TOKEN, role)
    _setCurrentRole(role)
  }
  const checkAuthStatus = useCallback(() => {
    let localToken = localStorage.getItem(AppConstant.ACCESS_TOKEN)

    if (localToken) {
      try {
        let tokenParts = localToken.split('.')

        let tokenPayload = tokenParts[1]
        let tokenPayloadStr = atob(tokenPayload)

        let decodedToken: any = JSON.parse(tokenPayloadStr)

        // let decodedToken: any = jwt.decode(localToken)
        if (decodedToken?.userId) {
          if (decodedToken.roles && decodedToken.roles.includes(AppConstant.CHIROPRACTOR)) {
            setCurrentRole(AppConstant.CHIROPRACTOR)
          }
          setUser(decodedToken)
          setIsAuthenticated(true)
          setToken(localToken)
        }
      } catch (err) {
        console.log(err)
      }
    }

    setIsAuthLoading(false)
  }, [setIsAuthenticated, setToken])

  useEffect(() => {
    checkAuthStatus()
  }, [setIsAuthenticated, setToken])

  async function signOut() {
    localStorage.removeItem(AppConstant.ACCESS_TOKEN)
    localStorage.removeItem(AppConstant.CURRENTROLE_TOKEN)
    localStorage.removeItem(AppConstant.LOCAL_STUDIO)
    window.location.href = '/'
  }

  return {
    isAuthenticated,
    token,
    user,
    isAuthLoading,
    signOut,
    currentRole,
    setCurrentRole,
    checkAuthStatus,
  }
}
